body {
    text-align: center;
}

form {
    border: thin solid #ddd;
    background-color: #fbfbfb;
    padding: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.login-button {
    margin-right: 1em;
}

.state-body {
    padding: 0.5em;
}

.card {
    margin: 2em;
}

.card-title {
    font-weight: bold;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.time-log {
    max-width: 50%;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .time-log {
        max-width: 100%;
        margin: auto;
    }
}

#query {
    text-align: left;
}
