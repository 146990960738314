.troubleshooting {
    text-align: left;
    margin: 2vw;
    margin-left: 20vw;
    margin-right: 20vw;
}

.anchor {
    color: black;
}

code {
    font-family: Consolas,"courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
}

pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
}
